export var selectorToolMapper = {
  antibody: {
    description: 'ANTIBODY_SELECTOR_TOOL_DESCRIPTION',
    facets: [{
      key: 'facet_clonality',
      componentType: 'select',
      label: 'Clonality',
      required: false
    }, {
      key: 'facet_web_titertest_app',
      componentType: 'select',
      label: 'Technique',
      required: false
    }, {
      key: 'facet_web_species_reactivity',
      componentType: 'select',
      label: 'Species Reactivity',
      required: false
    }, {
      key: 'facet_web_conjugate',
      componentType: 'multiselect',
      label: 'Conjugate',
      required: false
    }],
    searchLabel: 'ANTIBODY_SELECTOR_TOOL_SEARCH_LABEL',
    title: 'ANTIBODY_SELECTOR_TOOL_TITLE'
  },
  millex: {
    description: 'MILLEX_FILTER_SELECTOR_TOOL_DESCRIPTION',
    facets: [{
      key: 'facet_web_sterilization',
      componentType: 'radio',
      label: 'What type of filter do you need?',
      required: true
    }, {
      key: 'facet_web_titertest_app',
      componentType: 'multiselect',
      label: 'What applications describe your intended usage with the device?',
      required: true
    }, {
      key: 'facet_web_pore_size_dim1_um',
      componentType: 'multiselect',
      label: 'What is your desired pore size?',
      required: true
    }, {
      key: 'facet_web_comp_volume_ml',
      componentType: 'multiselect',
      label: 'Desired maximum sample volume?',
      required: true
    }],
    searchLabel: '',
    title: 'MILLEX_FILTER_SELECTOR_TOOL_TITLE'
  }
};