import { defineMessages } from 'react-intl';
export var selectorToolMessages = defineMessages({
  ANTIBODY_SELECTOR_TOOL_TITLE: {
    id: 'ANTIBODY_SELECTOR_TOOL_TITLE',
    defaultMessage: 'Antibody Product Selector'
  },
  ANTIBODY_SELECTOR_TOOL_DESCRIPTION: {
    id: 'ANTIBODY_SELECTOR_TOOL_DESCRIPTION',
    defaultMessage: 'Explore our collection of recombinant, polyclonal, and monoclonal antibodies.'
  },
  ANTIBODY_SELECTOR_TOOL_SEARCH_LABEL: {
    id: 'ANTIBODY_SELECTOR_TOOL_SEARCH_LABEL',
    defaultMessage: 'Antigen/Antibody Name'
  },
  MILLEX_FILTER_SELECTOR_TOOL_TITLE: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_TITLE',
    defaultMessage: 'Millex Filter Selector'
  },
  MILLEX_FILTER_SELECTOR_TOOL_DESCRIPTION: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_DESCRIPTION',
    defaultMessage: 'Choose the right syringe filter for your sample to obtain the best quality data, filtration performance, and reduce waste.'
  }
});
export default selectorToolMessages;