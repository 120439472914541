import { AnalyticsDispType } from '@src/types/graphql-types';
var bestSellers = 'Best Sellers';
var newProducts = 'New Arrivals';
var popularProducts = 'Popular Products';
var appPageBestSellersCodesMap = {
  S002bestsellers: 'S002',
  S003bestsellers: 'S003',
  S007bestsellers: 'S007',
  S012bestsellers: 'S012',
  S015bestsellers: 'S015',
  S017bestsellers: 'S017',
  S020bestsellers: 'S020',
  S022bestsellers: 'S022',
  S029bestsellers: 'S029',
  S053bestsellers: 'S053',
  S064bestsellers: 'S064',
  S071bestsellers: 'S071',
  S103bestsellers: 'S103',
  S107bestsellers: 'S107',
  S111bestsellers: 'S111',
  S114bestsellers: 'S114',
  S125bestsellers: 'S125'
};
var appPageNewProductsCodesMap = {
  S002newProducts: 'S002',
  S003newProducts: 'S003',
  S007newProducts: 'S007',
  S012newProducts: 'S012',
  S015newProducts: 'S015',
  S017newProducts: 'S017',
  S020newProducts: 'S020',
  S022newProducts: 'S022',
  S029newProducts: 'S029',
  S053newProducts: 'S053',
  S064newProducts: 'S064',
  S071newProducts: 'S071',
  S103newProducts: 'S103',
  S107newProducts: 'S107',
  S111newProducts: 'S111',
  S114newProducts: 'S114',
  S125newProducts: 'S125'
};
var catPageBestSellersCodesMap = {
  S138: 'S138',
  S140: 'S140',
  S151: 'S151',
  S194: 'S194',
  S198: 'S198',
  S199: 'S199',
  S241: 'S241',
  S280: 'S280',
  S307: 'S307',
  S315: 'S315',
  S319: 'S319',
  S324: 'S324',
  S348: 'S348',
  S366: 'S366',
  S372: 'S372',
  S395: 'S395',
  S402: 'S402',
  S863: 'S863',
  CP007: 'Cp007'
};

var createProductRecommendationPayload = function createProductRecommendationPayload(typeId, disp, headline) {
  return {
    typeId: typeId,
    disp: disp,
    headline: headline
  };
};

export var recommendationProductsConst = {
  bestsellers: createProductRecommendationPayload('homePageBestSellersAPI', AnalyticsDispType.BestSellers, bestSellers),
  buyAgain: createProductRecommendationPayload('biaAPI', AnalyticsDispType.BiaHp, 'Buy Again'),
  newProducts: createProductRecommendationPayload('newProductsAPI', AnalyticsDispType.NewProducts, newProducts),
  popular_products: createProductRecommendationPayload('homePagePopularProductsAPI', AnalyticsDispType.PopularProducts, popularProducts)
};

for (var code in appPageBestSellersCodesMap) {
  recommendationProductsConst[code] = createProductRecommendationPayload('appPageBestSellersAPI', AnalyticsDispType[appPageBestSellersCodesMap[code]], bestSellers);
}

for (var _code in appPageNewProductsCodesMap) {
  recommendationProductsConst[_code] = createProductRecommendationPayload('appPageNewProductsAPI', AnalyticsDispType[appPageNewProductsCodesMap[_code]], newProducts);
}

for (var _code2 in catPageBestSellersCodesMap) {
  recommendationProductsConst[_code2] = createProductRecommendationPayload('catPageBestSellersAPI', AnalyticsDispType[catPageBestSellersCodesMap[_code2]], newProducts);
}